<template>
    <vx-card title="Purchase Order">
        <div class="flex vx-input-group">
            <vs-tabs v-if="render" v-model="$route.params.order">
                <vs-tab label="Import PO">
                    <tab-log type="import-po-begbal"></tab-log>
                </vs-tab>
                <vs-tab label="Import PO Return">
                    <tab-log type="import-po-return-begbal"></tab-log>
                </vs-tab>
                <vs-tab label="Import GR">
                    <tab-log type="import-gr-begbal"></tab-log>
                </vs-tab>
                <vs-tab label="Import GR Return">
                    <tab-log type="import-gr-return-begbal"></tab-log>
                </vs-tab>
                <vs-tab label="Import Purchase Discount">
                    <tab-log type="import-purchase-discount-begbal"></tab-log>
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>
<script>
import TabLog from "./TabLogImport.vue";
export default {
    components: {
        TabLog,
    },
    data() {
        return {
            activeTab: "Draft",
            render: true,
        }
    },
    methods: {

    }
}
</script>
